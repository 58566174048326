import React, { Component } from 'react'
import styles from './NotFoundPage.module.scss'
import { FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'
import { getLink } from 'src/utils'

const links = [
  { text: 'readInteviews', link: 'interviews' },
  { text: 'readNews', link: 'news-list' },
  { text: 'watchVideo', link: 'videos' },
  { text: 'shareLifehacks', link: 'advices' },
  { text: 'listenPodcasts', link: 'podcasts' },
  { text: 'becomeAprojectHero', link: 'become-a-hero' },
]

class NotFoundPage extends Component {
  render() {
    const { locale } = this.props

    return (
      <>
        <div className={styles.background} />
        <div className={styles.inner}>
          <div className={styles.content}>
            <h1 className={styles.title}>404</h1>
            <p className={styles.subtitle}>
              <FormattedMessage id="home.pageNotFound" />
            </p>

            <div className={styles.list}>
              {links.map(({ text, link }, index) => (
                <Link className={styles.link} to={getLink(locale, link)}>
                  <FormattedMessage id={`home.${text}`} />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default NotFoundPage
