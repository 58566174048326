import React from 'react'
import Header from 'src/components/Header'
import PageHelmet from 'src/components/PageHelmet'
import NotFoundPage from 'src/components/NotFoundPage'
import withLayout from 'src/components/withLayout'

const NotFound = ({ locale }) => (
  <>
    <PageHelmet title="404" defer={false} />
    <Header full={true} locale={locale} />
    <NotFoundPage locale={locale} />
  </>
)

const customProps = {
  localeKey: 'error404',
  hideLangs: true,
}

export default withLayout(customProps)(NotFound)
